import React from 'react';
import Header from './Header';
import Footer from './Footer';

// Render header and footer
function Home() {
  return (
      <div>
        <Header />
        <Footer />
      </div>

  );
};

export default Home;
