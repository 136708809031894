import './App.css';
import Home from './components/Home';
import React from 'react';

function App() {
  return (
      <div>
        <Home />
      </div>
  );
}

export default App;
